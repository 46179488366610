.user-list-container {
    width: 80%;
    margin: auto;
    padding: 20px;
    background: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-list-header {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.user-list {
    list-style-type: none;
    padding: 0;
}

.user-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
}

.user-list-item:last-child {
    border-bottom: none;
}

.user-username {
    font-size: 1.2rem;
    color: #555;
}

.user-status {
    display: flex;
    align-items: center;
}

.user-status span {
    font-size: 0.9rem;
    color: #888;
}

.ant-btn {
    background-color: #ff4d4f;
    border: none;
    color: white;
    &:hover {
        background-color: #e60000;
        border: none;
    }
}
