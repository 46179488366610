/* Container for the entire page */
.employee-list-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f7f9fc;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Main title */
  .main-title {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Employee list container */
  .employee-list {
    width: 60%;
    margin-bottom: 40px;
  }
  
  /* Unordered list for employees */
  .employee-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Individual employee item */
  .employee-item {
    padding: 15px;
    margin: 10px 0;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .employee-item:hover {
    background-color: #eef6ff;
  }
  
  /* Section for dates */
  .dates-section {
    width: 60%;
  }
  
  /* Section title */
  .section-title {
    font-size: 28px;
    color: #444;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Unordered list for dates */
  .dates-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Individual date item */
  .date-item {
    padding: 12px;
    margin: 8px 0;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .date-item:hover {
    background-color: #d9f2fa;
  }
  
  /* Responsive layout */
  @media (max-width: 768px) {
    .employee-list,
    .dates-section {
      width: 100%;
    }
  
    .main-title {
      font-size: 28px;
    }
  
    .section-title {
      font-size: 24px;
    }
  }
  