.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
    background-color: #f5f5f5;
  }
  
  .section {
    margin-bottom: 20px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .text, .goal-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 6px;
  }
  
  .goal-container {
    margin-bottom: 12px;
  }
  
  .loading, .error, .no-data {
    text-align: center;
    font-size: 18px;
    color: #666;
  }
  
  .loading {
    font-weight: bold;
  }
  
  .error {
    color: red;
  }
  
  .no-data {
    color: #999;
  }
  