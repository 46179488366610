.nav-layout{
    width: 300px;
    /* margin: 0px; */
    position: fixed;
    height: 100%;
    left: 0px;
    top:120px;
}
.nav-layout .ant-menu{
    height:100%;
}
.nav-layout ant-menu ant-menu-root ant-menu-inline ant-menu-dark css-dev-only-do-not-override-1gwfwyx{
background:pink;
}
.nav-layout :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-dark.ant-menu-root.ant-menu-inline{
   
    height: 100%;
    padding:30px 0px 0px 10px;
}
.nav-child{
    position:absolute;
    top:120px;
    left:300px;
    
    width: 100%;
}
.layout{
    position: absolute;
    left: 300px;
    top: 120px;
    overflow: auto;
    
    width: calc(100vw - 300px);
    height: calc(100vh - 140px);
    padding: 40px;
    border-radius: 40px;
    /* width: 100%;
    height: 100%; */
}
.demo-logo-vertical{
    position: fixed;
    left: 0px;
    top:0px;
    width: 100%;
    height:120px;
    padding: 0px;
    /* margin: -5px; */
    background: #001529;
}